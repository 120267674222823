<template>
    <div>
      <div class="logoCentro d-block m-auto pt-5">
            <CenterLogo/>
        </div>
      <div class="container">
          <div class="row justify-content-center">
              <div class="col-6 d-flex flex-column align-itens-center">
                  <div class="containerError d-flex flex-column align-items-center">
                        <h1 class="notFoundTitle text-center">Página não encontrada!</h1>
                        <button :style="btnStyles" class="btn mt-4" type="button" @click.prevent="$router.push(`/${this.lang}/`)">Voltar a tela inicial</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import CenterLogo from '@/components/Login/CenterLogo.vue'
import { mapState } from 'vuex';
export default {
    name : 'NotFound',
    components: {
        CenterLogo
    },
    data() {
        return {
            btnStyles: {},
        }
    },
    computed:{
        ...mapState(['live', 'lang'])
    },
    methods: {
        setStyle() {
            let obj = this.$store.state.live;
            let prefix = obj.login;
            
            document.body.style.cssText = `background: url(${prefix.body.styles.backgroundImage}) ${prefix.body.styles.backgroundColor};`;            

            this.btnStyles = prefix.button;
        },
        getLive() {
            this.$store
                .dispatch('getLive', this.lang)
                .then(() => {
                    this.setStyle();
                })
                .catch(error => {
                    console.log(error);
                })
            
        },
        setLang(){
                this.$route.params.lang ? this.$store.commit('UPDATE_LANG', this.$route.params.lang) : this.$store.commit('UPDATE_LANG', 'pt')
        }
    },
    
    created() {
        this.setLang()
        this.getLive();
    }
}
</script>

<style scoped>

    .containerError {
        background: #fff;
        border-radius: 8px;
        padding: 30px 15px;
        box-shadow: 0 0 15px rgba(0,0,0,0.5);
    }

    .logoCentro{
        margin: 40px auto 150px auto !important;        
        max-width: 400px;
        z-index: 10;
    }

    
   
    @media (max-width: 500px) {
        .logoCentro{
            margin: 40px auto 100px auto !important;
            
            width: 300px;
        }
        
    }
</style>