<template>
    <div>
        <!-- <img class="img-fluid img-right" :src="center.src" alt="PETRONAS"> -->
        <img class="img-fluid img-right logo mb-0" src="@/assets/img/logopromo.png" alt="PETRONAS">
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex';

    export default {
        name: "CenterLogo",
        data() {
            return {
                center: {
                    src: null
                }
            }
        },
        computed: {
            ...mapState(['live'])
        },
        methods: {
            setData() {
                this.center.src = this.live.login.centerLogo.src;
            }
        },
        watch: {
            live() {
                this.setData();

            }
        },
        created() {
            this.setData();
        }
    }
</script>

<style scoped>
    .logo {
        width: 100%;
        max-width: 300px ;
        margin-bottom: 25px;
        transform: translateX(8px);
    }

    @media(max-width:991px) {
        .logo {
            max-width: 300px;
            margin-bottom: 0px;
        }
    }

    @media(max-width:767px) {
        .logo {
            max-width: 300px;
            margin-bottom: 0px;
        }
    }

    @media(max-width:575px) {
        .logo {
            max-width: 250px;
            margin-bottom: 0px;
        }
    }
</style>